.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  min-width: 26px;
  width: 26px;
  height: unset;
  line-height: 21px;
  padding: 0;
}

.ranges {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: unset;
}