/*-------------------
      Selection
--------------------*/

.ui.selection.list .list > .item,
.ui.selection.list > .item {
  cursor: pointer;
  background: transparent;
  padding: 0.5em 0.5em;
  margin: 0em;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5em;
  -webkit-transition: 0.1s color ease, 0.1s padding-left ease, 0.1s background-color ease;
  transition: 0.1s color ease, 0.1s padding-left ease, 0.1s background-color ease;
}

.ui.selection.list .list > .item:last-child,
.ui.selection.list > .item:last-child {
  margin-bottom: 0em;
}

.ui.selection.list.list > .item:hover,
.ui.selection.list > .item:hover {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.8);
}

.ui.selection.list .list > .item:active,
.ui.selection.list > .item:active {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.9);
}

.ui.selection.list .list > .item.active,
.ui.selection.list > .item.active {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

/* Inverted */

.ui.inverted.selection.list > .item,
.ui.inverted.selection.list > .item {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}

.ui.inverted.selection.list > .item:hover,
.ui.inverted.selection.list > .item:hover {
  background: rgba(255, 255, 255, 0.02);
  color: #ffffff;
}

.ui.inverted.selection.list > .item:active,
.ui.inverted.selection.list > .item:active {
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
}

.ui.inverted.selection.list > .item.active,
.ui.inverted.selection.list > .item.active {
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
}

/* Celled / Divided Selection List */

.ui.celled.selection.list .list > .item,
.ui.divided.selection.list .list > .item,
.ui.celled.selection.list > .item,
.ui.divided.selection.list > .item {
  border-radius: 0em;
}

/*--------------------
     Basic Select
---------------------*/

.ui.form select {
  display: block;
  height: auto;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  padding: 0.62em 1em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

/*--------------------
       Dropdown
---------------------*/

/* Block */

.ui.form .field > .selection.dropdown {
  width: 100%;
}

.ui.form .field > .selection.dropdown > .dropdown.icon {
  float: right;
}

/* Inline */

.ui.form .inline.fields .field > .selection.dropdown,
.ui.form .inline.field > .selection.dropdown {
  width: auto;
}

.ui.form .inline.fields .field > .selection.dropdown > .dropdown.icon,
.ui.form .inline.field > .selection.dropdown > .dropdown.icon {
  float: none;
}

/*--------------------
       UI Input
---------------------*/

/* Block */

.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
  width: 100%;
}

/* Inline  */

.ui.form .inline.fields .field:not(.wide) .ui.input,
.ui.form .inline.field:not(.wide) .ui.input {
  width: auto;
  vertical-align: middle;
}

/* Auto Input */

.ui.form .fields .field .ui.input input,
.ui.form .field .ui.input input {
  width: auto;
}

/* Full Width Input */

.ui.form .ten.fields .ui.input input,
.ui.form .nine.fields .ui.input input,
.ui.form .eight.fields .ui.input input,
.ui.form .seven.fields .ui.input input,
.ui.form .six.fields .ui.input input,
.ui.form .five.fields .ui.input input,
.ui.form .four.fields .ui.input input,
.ui.form .three.fields .ui.input input,
.ui.form .two.fields .ui.input input,
.ui.form .wide.field .ui.input input {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 0px;
}

/*--------------------
   Types of Messages
---------------------*/

.ui.form .success.message,
.ui.form .warning.message,
.ui.form .error.message {
  display: none;
}

/* Assumptions */

.ui.form .message:first-child {
  margin-top: 0px;
}

/*--------------------
   Validation Prompt
---------------------*/

.ui.form .field .prompt.label {
  white-space: normal;
  background: #FFFFFF !important;
  border: 1px solid #E0B4B4 !important;
  color: #9F3A38 !important;
}

.ui.form .inline.fields .field .prompt,
.ui.form .inline.field .prompt {
  vertical-align: top;
  margin: -0.25em 0em -0.5em 0.5em;
}

.ui.form .inline.fields .field .prompt:before,
.ui.form .inline.field .prompt:before {
  border-width: 0px 0px 1px 1px;
  bottom: auto;
  right: auto;
  top: 50%;
  left: 0em;
}

/*--------------
      Menu
---------------*/

.ui.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1rem 0em;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: #FFFFFF;
  font-weight: normal;
  border: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  min-height: 2.85714286em;
}

.ui.menu:after {
  content: '';
  display: block;
  height: 0px;
  clear: both;
  visibility: hidden;
}

.ui.menu:first-child {
  margin-top: 0rem;
}

.ui.menu:last-child {
  margin-bottom: 0rem;
}

/*--------------
    Sub-Menu
---------------*/

.ui.menu .menu {
  margin: 0em;
}

.ui.menu:not(.vertical) > .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/*--------------
      Item
---------------*/

.ui.menu:not(.vertical) .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ui.menu .item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  padding: 0.92857143em 1.14285714em;
  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
  -webkit-transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
}

.ui.menu > .item:first-child {
  border-radius: 0.28571429rem 0px 0px 0.28571429rem;
}

/*******************************
             States
*******************************/

/*--------------
      Hover
---------------*/

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Pressed
---------------*/

.ui.link.menu .item:active,
.ui.menu .link.item:active,
.ui.menu a.item:active {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Active
---------------*/

.ui.menu .active.item {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  font-weight: normal;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.menu .active.item > i.icon {
  opacity: 1;
}

/*--------------
  Active Hover
---------------*/

.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Disabled
---------------*/

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
  cursor: default !important;
  background-color: transparent !important;
  color: rgba(40, 40, 40, 0.3) !important;
}

/*******************************
             Types
*******************************/

/*------------------
Floated Menu / Item
-------------------*/

/* Left Floated */

.ui.menu:not(.vertical) .left.item,
.ui.menu:not(.vertical) :not(.dropdown) > .left.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto !important;
}

/* Right Floated */

.ui.menu:not(.vertical) .right.item,
.ui.menu:not(.vertical) .right.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto !important;
}

/* Swapped Borders */

.ui.menu .right.item::before,
.ui.menu .right.menu > .item::before {
  right: auto;
  left: 0;
}

/*--------------
    Vertical
---------------*/

.ui.vertical.menu {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
}

/*--- Item ---*/

.ui.vertical.menu .item {
  display: block;
  background: none;
  border-top: none;
  border-right: none;
}

.ui.vertical.menu > .item:first-child {
  border-radius: 0.28571429rem 0.28571429rem 0px 0px;
}

.ui.vertical.menu > .item:last-child {
  border-radius: 0px 0px 0.28571429rem 0.28571429rem;
}

/*--- Label ---*/

.ui.vertical.menu .item > .label {
  float: right;
  text-align: center;
}

/*--- Icon ---*/

.ui.vertical.menu .item > i.icon {
  width: 1.18em;
  float: right;
  margin: 0em 0em 0em 0.5em;
}

.ui.vertical.menu .item > .label + i.icon {
  float: none;
  margin: 0em 0.5em 0em 0em;
}

/*--- Border ---*/

.ui.vertical.menu .item:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 0px;
  width: 100%;
  height: 1px;
  background: rgba(34, 36, 38, 0.1);
}

.ui.vertical.menu .item:first-child:before {
  display: none !important;
}

/*--- Sub Menu ---*/

.ui.vertical.menu .item > .menu {
  margin: 0.5em -1.14285714em 0em;
}

.ui.vertical.menu .menu .item {
  background: none;
  padding: 0.5em 1.33333333em;
  font-size: 0.85714286em;
  color: rgba(0, 0, 0, 0.5);
}

.ui.vertical.menu .item .menu a.item:hover,
.ui.vertical.menu .item .menu .link.item:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ui.vertical.menu .menu .item:before {
  display: none;
}

/* Vertical Active */

.ui.vertical.menu .active.item {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0em;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.vertical.menu > .active.item:first-child {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui.vertical.menu > .active.item:last-child {
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

.ui.vertical.menu > .active.item:only-child {
  border-radius: 0.28571429rem;
}

.ui.vertical.menu .active.item .menu .active.item {
  border-left: none;
}

.ui.vertical.menu .item .menu .active.item {
  background-color: transparent;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.95);
}

/* Border */

.ui.menu .item:before {
  position: absolute;
  content: '';
  top: 0%;
  right: 0px;
  height: 100%;
  width: 1px;
  background: rgba(34, 36, 38, 0.1);
}

/*--------------
  Text Content
---------------*/

.ui.menu .text.item > *,
.ui.menu .item > a:not(.ui),
.ui.menu .item > p:only-child {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: 1.3;
}

.ui.menu .item > p:first-child {
  margin-top: 0;
}

.ui.menu .item > p:last-child {
  margin-bottom: 0;
}

/*--------------
      Icons
---------------*/

.ui.menu .item > i.icon {
  opacity: 0.9;
  float: none;
  margin: 0em 0.35714286em 0em 0em;
}

/*--------------
     Button
---------------*/

.ui.menu:not(.vertical) .item > .button {
  position: relative;
  top: 0em;
  margin: -0.5em 0em;
  padding-bottom: 0.78571429em;
  padding-top: 0.78571429em;
  font-size: 1em;
}

/*----------------
 Grid / Container
-----------------*/

.ui.menu > .grid,
.ui.menu > .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
}

/*--------------
   Pagination
---------------*/

.ui.pagination.menu {
  margin: 0em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.ui.pagination.menu .item:last-child {
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

.ui.compact.menu .item:last-child {
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

.ui.pagination.menu .item:last-child:before {
  display: none;
}

.ui.pagination.menu .item {
  min-width: 3em;
  text-align: center;
}

.ui.pagination.menu .icon.item i.icon {
  vertical-align: top;
}

/* Active */

.ui.pagination.menu .active.item {
  border-top: none;
  padding-top: 0.92857143em;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*--------------
     Sizes
---------------*/

/* Mini */

.ui.mini.menu {
  font-size: 0.78571429rem;
}

.ui.mini.vertical.menu {
  width: 9rem;
}

/* Tiny */

.ui.tiny.menu {
  font-size: 0.85714286rem;
}

.ui.tiny.vertical.menu {
  width: 11rem;
}

/* Small */

.ui.small.menu {
  font-size: 0.92857143rem;
}

.ui.small.vertical.menu {
  width: 13rem;
}

/* Medium */

.ui.menu {
  font-size: 1rem;
}

.ui.vertical.menu {
  width: 15rem;
}

/* Large */

.ui.large.menu {
  font-size: 1.07142857rem;
}

.ui.large.vertical.menu {
  width: 18rem;
}

/* Huge */

.ui.huge.menu {
  font-size: 1.21428571rem;
}

.ui.huge.vertical.menu {
  width: 22rem;
}

/* Big */

.ui.big.menu {
  font-size: 1.14285714rem;
}

.ui.big.vertical.menu {
  width: 20rem;
}

/* Massive */

.ui.massive.menu {
  font-size: 1.28571429rem;
}

.ui.massive.vertical.menu {
  width: 25rem;
}